<template>
  <footer class="legal-notice is-align-self-center pb-3 has-text-shadow">
    <p class="has-text-centered is-size-7">
      Age of Empires Online © Microsoft Corporation. This gear stat utility was
      created under Microsoft's
      <a
        href="https://www.xbox.com/en-us/developers/rules"
        target="_blank"
        rel="nofollow"
      >
        Game Content Usage Rules
      </a>
      using assets from Age of Empires Online, and it is not endorsed by or
      affiliated with Microsoft.
    </p>
  </footer>
</template>

<script>
export default {
  name: "LegalNotice"
}
</script>

<style lang="scss" scoped>
.legal-notice {
  color: $font-color;
  max-width: 400px;
}
</style>
